import { wrap } from "../_"

type OauthProvider = string // TODO

interface LoginData { username: string, password: string}

const pfx = "/login"

export default (a: NuxtAxiosInstance) => ({
  getOauthUrl:
    (provider: OauthProvider, redirectUrl: string, cfg?: AxiosConfig) =>
      wrap(
        a.$get(`${pfx}/oauth/${provider}`,
          { params: { redirectUrl }, ...cfg })),

  getOwnerData: (provider: OauthProvider, redirectUrl: string, code: string,
    cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/oauth/${provider}/owner`,
      { params: { redirectUrl, code }, ...cfg })),

  login: (data: LoginData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}`, data,
      { ...cfg })),
})
