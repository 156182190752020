import { wrap } from "../_"

const pfx = "/dadata"

export default (a: NuxtAxiosInstance) => ({
  getCompanies: (query: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/suggest/company`, { query },
      { cache: true, ...cfg })),

  getAddresses: (query: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/suggest/address`, { query },
      { cache: true, ...cfg })),

  getSchools: (query: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/suggest/school`, { query },
      { cache: true, ...cfg })),
})
